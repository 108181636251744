<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <div class="w-full max-w-md bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <h2 class="text-2xl font-bold text-center mb-4 text-gray-800 dark:text-gray-100">
          {{ stage === 'request' ? 'Redefinir Senha' : 'Nova Senha' }}
        </h2>
        <p v-if="stage === 'request'" class="text-center text-gray-600 dark:text-gray-400 mb-6">
          Insira o seu e-mail para receber o link de redefinição de senha.
        </p>
        <p v-else class="text-center text-gray-600 dark:text-gray-400 mb-6">
          Insira sua nova senha para concluir a redefinição.
        </p>
  
        <!-- Mensagens de erro -->
        <transition name="fade">
          <div
            v-if="errorMessage"
            class="mb-4 p-4 text-red-700 bg-red-100 border-l-4 border-red-500 rounded"
          >
            {{ errorMessage }}
          </div>
        </transition>
  
        <!-- Mensagens de sucesso -->
        <transition name="fade">
          <div
            v-if="successMessage"
            class="mb-4 p-4 text-green-700 bg-green-100 border-l-4 border-green-500 rounded"
          >
            {{ successMessage }}
          </div>
        </transition>
  
        <form @submit.prevent="handleSubmit">
          <!-- Solicitação de redefinição -->
          <div v-if="stage === 'request'">
            <div class="mb-4">
              <label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Email
              </label>
              <input
                v-model="email"
                type="email"
                id="email"
                required
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100"
                placeholder="Digite seu email"
              />
            </div>
          </div>
  
          <!-- Redefinição de senha -->
          <div v-else>
            <div class="mb-4">
              <label for="new-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Nova Senha
              </label>
              <input
                v-model="newPassword"
                type="password"
                id="new-password"
                required
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100"
                placeholder="Digite sua nova senha"
              />
            </div>
            <div class="mb-4">
              <label for="confirm-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Confirme a Senha
              </label>
              <input
                v-model="confirmPassword"
                type="password"
                id="confirm-password"
                required
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100"
                placeholder="Confirme sua nova senha"
              />
            </div>
          </div>
  
          <!-- Botão de envio -->
          <button
            type="submit"
            class="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-md transition duration-300"
          >
            {{ stage === 'request' ? 'Enviar Link' : 'Redefinir Senha' }}
          </button>
        </form>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import axios from 'axios'
  
  const stage = ref('request') // 'request' ou 'reset'
  const email = ref('')
  const newPassword = ref('')
  const confirmPassword = ref('')
  const successMessage = ref('')
  const errorMessage = ref('')
  const isSubmitting = ref(false) // Para bloquear múltiplos envios
  const isTokenValidated = ref(false) // Para controlar a validação do token
  const route = useRoute()
  const router = useRouter()
  
  const API_URL = process.env.VUE_APP_API_URL
  
  // Define o estágio com base no token na URL
  if (route.query.token && route.query.uidb64) {
    validateToken(route.query.uidb64, route.query.token)
      .then(isValid => {
        if (isValid) {
          stage.value = 'reset'
        } else {
          errorMessage.value = 'Token inválido ou já utilizado. Por favor, solicite uma nova redefinição.'
          setTimeout(() => {
            router.push({ name: 'Login' }) // Redireciona para a página de solicitação
          }, 3000)
        }
      })
      .catch(() => {
        errorMessage.value = 'Erro ao validar o token. Por favor, tente novamente mais tarde.'
        setTimeout(() => {
          router.push({ name: 'Login' })
        }, 3000)
      })
      .finally(() => {
        isTokenValidated.value = true // Marca a validação como concluída
      })
  }
  
  async function validateToken(uidb64, token) {
    try {
      const response = await axios.post(`${API_URL}/api/v1/password/reset/validate-token/`, { uidb64, token })
      return response.data.valid
    } catch (error) {
      return false
    }
  }
  
  const handleSubmit = async () => {
    if (isSubmitting.value) return
    isSubmitting.value = true
  
    errorMessage.value = ''
    successMessage.value = ''
  
    if (stage.value === 'request') {
      // Solicitação de link de redefinição
      try {
        const response = await axios.post(`${API_URL}/api/v1/password/reset/`, { email: email.value })
        successMessage.value = response.data.detail
      } catch (error) {
        errorMessage.value = error.response?.data?.detail || 'Erro ao solicitar redefinição.'
      } finally {
        isSubmitting.value = false
      }
    } else {
      // Redefinição de senha
      if (newPassword.value !== confirmPassword.value) {
        errorMessage.value = 'As senhas não conferem.'
        isSubmitting.value = false
        return
      }
  
      try {
        const response = await axios.post(`${API_URL}/api/v1/password/reset/confirm/`, {
          uidb64: route.query.uidb64,
          token: route.query.token,
          new_password: newPassword.value,
        })
        successMessage.value = response.data.detail
        setTimeout(() => {
          router.push({ name: 'Login' })
        }, 2000)
      } catch (error) {
        errorMessage.value = error.response?.data?.detail || 'Erro ao redefinir senha.'
        if (errorMessage.value.includes('inválido') || errorMessage.value.includes('expirado')) {
          setTimeout(() => {
            router.push({ name: 'ResetPasswordRequest' })
          }, 3000)
        }
      } finally {
        isSubmitting.value = false
      }
    }
  }

  onMounted(() => {
});
  </script>
  
  <style>
  /* Adicione seu estilo personalizado aqui */
  </style>
  