// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginScreen from '../components/LoginScreen.vue';
import RegisterScreen from '../components/RegisterScreen.vue';
import ResetPassword from '../components/ResetPassword.vue';
import EventosList from '../components/EventosList.vue';
import DetalhesEventos from '../components/DetalhesEventos.vue';
import MetricsView from '../components/MetricsView.vue';
// Importar o componente de página 404
import NotFound from '../components/NotFound.vue';
import Offline from '../components/OfflineScreen.vue';
import DashboardEquipamentos from '../components/DashboardEquipamentos.vue';
import DashboardInstancias from '@/components/DashboardInstancias.vue';
import DashboardUnidades from "@/components/DashboardUnidades.vue";
import {
  ref,
} from "vue";

const API_URL = process.env.VUE_APP_API_URL;


const areNotificationsEnabled = ref(false); // Estado reativo para notificações

// Função para validar o token com o backend e obter estado das notificações
async function validateTokenWithBackend(token) {
  try {
    const response = await fetch(`${API_URL}/api/v1/auth/validate`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (!response.ok || !data?.valid) {
      throw new Error(data?.message || "Token inválido");
    }

    // Armazena os dados do usuário no sessionStorage
    sessionStorage.setItem("user_groups", JSON.stringify(data.groups || []));
    sessionStorage.setItem("firstname", data.firstname || "");
    sessionStorage.setItem("lastname", data.lastname || "");
    sessionStorage.setItem(
      "notifications_enabled",
      data.notifications_enabled ? "true" : "false"
    );

    // ✅ Atualiza o estado reativo do Vue
    areNotificationsEnabled.value = !!data.notifications_enabled;

    return {
      valid: true,
      firstname: data.firstname || "",
      lastname: data.lastname || "",
      groups: data.groups || [],
      notifications_enabled: !!data.notifications_enabled,
    };
  } catch (error) {
    console.error("Erro ao validar token:", error);

    // Se houver erro, garantir que `areNotificationsEnabled` volte ao estado padrão
    areNotificationsEnabled.value = false;

    return { valid: false };
  }
}




// Função de autenticação
async function isAuthenticated() {
  const token = sessionStorage.getItem('api_token');
  const expiry = sessionStorage.getItem('token_expiry');

  if (!token || !expiry) {
    return false;
  }

  const now = Date.now();
  if (now > parseInt(expiry, 10)) {
    sessionStorage.removeItem('api_token');
    sessionStorage.removeItem('token_expiry');
    return false;
  }

  const isValid = await validateTokenWithBackend(token);

  if (!isValid) {
    sessionStorage.removeItem('api_token');
    sessionStorage.removeItem('token_expiry');
  }

  return isValid;
}

// Função para obter os grupos do usuário
function getUserGroups() {
  const groups = sessionStorage.getItem('user_groups');
  return groups ? JSON.parse(groups) : [];
}

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginScreen,
    meta: { title: "Login - Dashboard" },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterScreen,
    meta: { title: "Cadastro - Dashboard" },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { title: "Recuperar Senha - Dashboard" },
  },
  {
    path: "/eventos",
    name: "EventosList",
    component: EventosList,
    meta: {
      requiresAuth: true,
      roles: ["manager"],
      title: "Eventos - Dashboard",
    },
  },
  {
    path: "/eventos/:id",
    name: "DetalhesEvento",
    component: DetalhesEventos,
    meta: { requiresAuth: true, title: "Detalhes do Evento - Dashboard" },
    props: true,
  },
  {
    path: "/dashboardequipamentos",
    name: "DashboardEquipamentos",
    component: DashboardEquipamentos,
    meta: { requiresAuth: true, title: "Equipamentos - Dashboard" },
  },
  {
    path: "/dashboardinstancias",
    name: "DashboardInstancias",
    component: DashboardInstancias,
    meta: {
      requiresAuth: true,
      roles: ["manager", "admin"],
      title: "Instâncias - Dashboard",
    },
  },
  {
    path: "/dashboardunidades",
    name: "DashboardUnidades",
    component: DashboardUnidades,
    meta: {
      requiresAuth: true,
      roles: ["manager", "admin"],
      title: "Unidades - Dashboard",
    },
  },
  {
    path: "/metrics/:id",
    name: "Metrics",
    component: MetricsView,
    meta: {
      requiresAuth: true,
      roles: ["manager", "admin"],
      title: "Métricas - Dashboard",
    },
    props: true,
  },
  {
    path: "/offline",
    name: "Offline",
    component: Offline,
    meta: { title: "Offline - Dashboard" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: { title: "Página Não Encontrada - Dashboard" },
  },
];



// Criação do roteador
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    // Atualiza o título da página com base na rota
    if (to.meta.title) {
      document.title = to.meta.title;
    } else {
      document.title = "Dashboard"; // Título padrão
    }

    // Verifica se o navegador está offline
    if (!navigator.onLine) {
      if (to.name !== "Offline") {
        // Redireciona para a tela "Offline" e preserva a rota de destino
        return next({
          name: "Offline",
          query: { redirect: to.fullPath }, // Salva a rota original na query
        });
      }
    } else if (to.name === "Offline") {
      // Se estiver online e na rota "Offline", redireciona para a página principal
      const redirect = to.query.redirect || "/";
      return next(redirect);
    }

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    // Verifica se a rota não exige autenticação
    if (!requiresAuth) {
      return next();
    }

    const isLoggedIn = await isAuthenticated();

    if (!isLoggedIn) {
      if (to.name === "Login") {
        // Evita redirecionamento para login se já estamos na tela de login
        return next();
      }
      return next({ name: "Login", query: { accessDenied: true } });
    }

    const roles = to.meta.roles || [];
    const userGroups = getUserGroups();

    if (roles.length > 0 && !roles.some((role) => userGroups.includes(role))) {
      return next({
        name: "DashboardEquipamentos",
        query: { unauthorized: true },
      });
    }

    next();
  } catch (error) {
    next({ name: "Login", query: { error: true } });
  }
});









export default router;
