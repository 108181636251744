<template>
    <BaseTemplate app-name="Detalhes" page-title="">
        <template #content>
            <div class="flex justify-center items-center min-h-screen px-6 py-8">
                <div class="container max-w-6xl bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
                    <div class="flex justify-start mb-6">
                        <button @click="voltar"
                            class="bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-white px-4 py-2 rounded hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors">
                            ← Voltar
                        </button>
                    </div>
                    <div class="grid lg:grid-cols-3 gap-8">
                        <!-- Coluna Esquerda: Detalhes do Evento -->
                        <div class="lg:col-span-2">
                            <h1
                                class="text-3xl font-bold mb-6 text-gray-900 dark:text-white border-b pb-3 border-gray-200 dark:border-gray-700">
                                Detalhes do Evento
                            </h1>

                            <div v-if="isLoading" class="text-center py-8">
                                <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
                            </div>

                            <div v-else-if="error"
                                class="mb-4 p-4 text-red-700 bg-red-100 border-l-4 border-red-500 rounded">
                                <p>{{ error }}</p>
                            </div>
                            <div v-else>
                                <div class="grid md:grid-cols-2 gap-4 mb-6">
                                    <div>
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">ID</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ evento.id }}
                                        </p>
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Unidade</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ evento.unidade_nome || 'Unidade não informada' }}
                                        </p>
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Entidade</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ evento.entidade_nome || 'Entidade não informada' }}
                                        </p>
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Equipamento</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ evento.equipamento_nome || 'Equipamento não informado' }}
                                        </p>
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Categoria</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ formatarTexto('categoria_evento', evento.categoria_evento) }}
                                        </p>
                                    </div>
                                    <div>
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Status</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ formatarTexto('status', evento.status) }}
                                        </p>
                                    </div>
                                    <div>
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                            Data de Abertura</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ formatarData(evento.data_abertura) }}
                                        </p>
                                    </div>
                                    <div>
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                            Data de Inicio</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ formatarData(evento.data_inicio) }}
                                        </p>
                                    </div>
                                    <div>
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                            Previsão de Conclusão</label>
                                        <p
                                            class="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded">
                                            {{ formatarData(evento.data_final) }}
                                        </p>
                                    </div>
                                </div>

                                <div class="mb-6">
                                    <label
                                        class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Descrição</label>
                                    <div
                                        class="bg-gray-100 dark:bg-gray-700 p-4 rounded text-gray-900 dark:text-white min-h-[150px]">
                                        {{ evento.descricao }}
                                    </div>
                                </div>





                                <!-- Botões de Ação -->
                                <div class="flex flex-wrap gap-4" v-if="evento.status !== 'finalizado' && isManager">
                                    <!-- Botão para marcar como em andamento -->
                                    <button v-if="evento.status === 'pendente'" @click="alterarParaEmAndamento"
                                        class="flex items-center justify-center gap-2 bg-yellow-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-yellow-700 transition-all disabled:opacity-50 disabled:cursor-not-allowed w-full lg:w-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="lucide lucide-play-circle">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <polyline points="10 8 16 12 10 16"></polyline>
                                        </svg>
                                        <span class="whitespace-normal text-center">
                                            Marcar como Em Andamento
                                        </span>
                                    </button>

                                    <!-- Botão para alterar data de início -->
                                    <button v-if="evento.status === 'pendente'" @click="abrirModal('alterarDataInicio')"
                                        class="flex items-center justify-center gap-2 bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all disabled:opacity-50 disabled:cursor-not-allowed w-full lg:w-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="lucide lucide-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" x2="16" y1="2" y2="6"></line>
                                            <line x1="8" x2="8" y1="2" y2="6"></line>
                                            <line x1="3" x2="21" y1="10" y2="10"></line>
                                        </svg>
                                        <span class="whitespace-normal text-center">
                                            Alterar Data de Início
                                        </span>
                                    </button>
                                    <button @click="abrirModal('finalizar')"
                                        class="flex items-center justify-center gap-2 bg-green-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-all disabled:opacity-50 disabled:cursor-not-allowed w-full lg:w-auto"
                                        :disabled="isFinalizando">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="lucide lucide-check">
                                            <path d="M20 6 9 17l-5-5" />
                                        </svg>
                                        <span class="whitespace-normal text-center">
                                            {{ isFinalizando ? 'Finalizando...' : 'Finalizar Evento' }}
                                        </span>
                                    </button>

                                    <button @click="abrirModal('atualizarData')"
                                        class="flex items-center justify-center gap-2 bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all w-full lg:w-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="lucide lucide-timer">
                                            <line x1="10" x2="14" y1="2" y2="2" />
                                            <line x1="12" x2="15" y1="14" y2="11" />
                                            <circle cx="12" cy="14" r="8" />
                                        </svg>
                                        <span class="whitespace-normal text-center">
                                            Atualizar Data de Previsão
                                        </span>
                                    </button>
                                </div>



                                <!-- Mensagem quando o evento está finalizado -->
                                <div v-if="evento.status === 'finalizado' && isManager"
                                    class="text-blue-600 font-semibold flex items-center justify-center text-center rounded bg-blue-300 p-4 md:p-6 lg:p-8">
                                    <p class="text-sm md:text-base xs:text-xs">
                                        Este evento está finalizado e não pode mais ser editado.
                                    </p>
                                </div>

                            </div>
                        </div>

                        <!-- Coluna Direita: Comentários -->
                        <div>
                            <div class="bg-gray-100 dark:bg-gray-800 shadow-lg rounded-lg p-6">
                                <h2
                                    class="text-2xl font-bold mb-4 text-gray-900 dark:text-white border-b pb-2 border-gray-200 dark:border-gray-700">
                                    Comentários
                                </h2>

                                <!-- Lista de Comentários -->
                                <div v-if="comentarios.length === 0"
                                    class="text-center text-gray-500 dark:text-gray-400 py-4">
                                    Nenhum comentário ainda.
                                </div>
                                <div v-else class="max-h-[500px] overflow-y-auto pr-2 mb-4 space-y-4">
                                    <div v-for="comentario in comentarios" :key="comentario.id"
                                        class="bg-gray-100 dark:bg-gray-700 p-4 rounded shadow-sm">
                                        <div class="flex justify-between items-center mb-2">
                                            <p class="text-sm text-gray-600 dark:text-gray-300">
                                                <strong>{{ comentario.usuario }}</strong>
                                            </p>
                                            <span class="text-xs text-gray-500 dark:text-gray-400">
                                                {{ formatarData(comentario.data_criacao) }}
                                            </span>
                                        </div>
                                        <p class="text-gray-900 dark:text-white">{{ comentario.comentario }}</p>
                                    </div>
                                </div>

                                <!-- Adicionar Comentário -->
                                <div class="bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
                                    <h3 class="text-lg font-bold mb-4 text-gray-900 dark:text-white">Adicionar
                                        Comentário
                                    </h3>
                                    <textarea v-model="novoComentario"
                                        class="w-full border dark:border-gray-600 rounded px-3 py-2 mb-4 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500"
                                        rows="4" placeholder="Digite seu comentário"></textarea>
                                    <button @click="adicionarComentario"
                                        class="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 transition-colors"
                                        :disabled="isAddingComentario">
                                        {{ isAddingComentario ? 'Enviando...' : 'Enviar' }}
                                    </button>
                                    <div v-if="errorComentario" class="text-red-500 mt-2 text-center">
                                        {{ errorComentario }}
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- Modal -->
                        <div v-if="modalAberto"
                            class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
                                <h2 class="text-xl font-bold text-gray-900 dark:text-white mb-4">
                                    {{ modalTitulo }}
                                </h2>
                                <div v-if="modalTipo === 'finalizar'">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Status Após Finalização
                                    </label>
                                    <select v-model="modalInput"
                                        class="w-full border dark:border-gray-600 rounded px-3 py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500">
                                        <option value="" disabled>Selecione um status</option>
                                        <option value="em_funcionamento">Resolvido</option>
                                    </select>
                                </div>

                                <div v-else-if="modalTipo === 'alterarDataInicio'">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Nova Data de Início (YYYY-MM-DD)
                                    </label>
                                    <input v-model="modalInput" type="date"
                                        class="w-full border dark:border-gray-600 rounded px-3 py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500">
                                </div>

                                <div v-else-if="modalTipo === 'atualizarData'">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Nova Data de Previsão (YYYY-MM-DD)
                                    </label>
                                    <input v-model="modalInput" type="date"
                                        class="w-full border dark:border-gray-600 rounded px-3 py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500">
                                </div>

                                <div class="mt-4 flex justify-end gap-4">
                                    <button @click="fecharModal"
                                        class="bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-white px-4 py-2 rounded hover:bg-gray-400 dark:hover:bg-gray-500">
                                        Cancelar
                                    </button>
                                    <button @click="confirmarModal"
                                        class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </template>
    </BaseTemplate>
</template>


<script setup>
import BaseTemplate from './BaseTemplate.vue';
import { ref, onMounted, computed} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { RefreshCw } from 'lucide-vue-next';

const route = useRoute();
const router = useRouter();
const evento = ref(null);
const isLoading = ref(true);
const error = ref(null);
const comentarios = ref([]);
const novoComentario = ref('');
const isFinalizando = ref(false);
const isAddingComentario = ref(false);
const errorComentario = ref(null);
const unidades = ref([]);
const equipamentos = ref([]);
const entidades = ref([]);
const modalAberto = ref(false);
const modalTipo = ref('');
const modalInput = ref('');
const modalTitulo = ref('');


// Função para carregar as unidades
async function carregarUnidades() {
    try {
        const response = await axios.get('/api/v1/unidades/');
        unidades.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar unidades:', error);
    }
}

// Função para carregar os equipamentos
async function carregarEquipamentos() {
    try {
        const response = await axios.get('/api/v1/equipamentos/');
        equipamentos.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar equipamentos:', error);
    }
}

// Abrir Modal
function abrirModal(tipo) {
    modalTipo.value = tipo;
    modalTitulo.value = tipo === 'finalizar' ? 'Finalizar Evento' : 'Atualizar Data de Previsão';
    modalInput.value = '';
    modalAberto.value = true;
}


// Função para carregar as entidades
async function carregarEntidades() {
    try {
        const response = await axios.get('/api/v1/entidades/');
        entidades.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar entidades:', error);
    }
}

// Função para mapear os IDs para os nomes
function obterNomePorId(lista, id) {
    const item = lista.find((item) => item.id === id);
    return item ? item.nome : 'Desconhecido';
}




// Carregar Comentários
async function carregarComentarios(eventoId) {
    try {
        const response = await axios.get(`/api/v1/eventos/${eventoId}/comentarios/`);
        comentarios.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar comentários:', error);
    }
}

// Função para formatar texto (capitalize ou mapear)
function formatarTexto(chave, valor) {
    const map = {
        categoria_evento: {
            adverso: 'Adverso',
            outro: 'Outro',
        },
        status: {
            em_atendimento: 'Em Atendimento',
            finalizado: 'Finalizado',
            em_espera: 'Em Espera',
        },
    };

    return map[chave]?.[valor] || valor.charAt(0).toUpperCase() + valor.slice(1).replace('_', ' ');
}

// Recuperando grupos de usuário do sessionStorage
const userGroups = computed(() => {
    const groups = sessionStorage.getItem('user_groups');
    return groups ? JSON.parse(groups) : [];  // Retorna os grupos ou um array vazio
});

// Verifica se o usuário pertence ao grupo 'manager'
const isManager = computed(() => userGroups.value.includes('manager'));



async function adicionarComentario() {
    if (!novoComentario.value.trim()) {
        errorComentario.value = 'O comentário não pode estar vazio.';
        return;
    }

    isAddingComentario.value = true;
    errorComentario.value = null;

    try {
        // Incluindo o ID do evento no payload
        const response = await axios.post(`/api/v1/eventos/${evento.value.id}/comentarios/`, {
            evento: evento.value.id, // ID do evento
            comentario: novoComentario.value, // Texto do comentário
        });

        // Adicionar o novo comentário na lista
        comentarios.value.push(response.data);

        // Limpar o campo de comentário
        novoComentario.value = '';
    } catch (error) {
        console.error('Erro ao adicionar comentário:', error);
        errorComentario.value = 'Erro ao adicionar comentário. Tente novamente.';
    } finally {
        isAddingComentario.value = false;
    }
}

function voltar() {
    router.push({ name: 'EventosList' });
}

// Fechar Modal
function fecharModal() {
    modalAberto.value = false;
}

// Confirmar Ação no Modal
async function confirmarModal() {
    if (modalTipo.value === 'finalizar') {
        await finalizarEvento(modalInput.value);
    } else if (modalTipo.value === 'alterarDataInicio') {
        await alterarDataInicio(modalInput.value);
    } else if (modalTipo.value === 'atualizarData') {
        await atualizarDataPrevisao(modalInput.value);
    }
    fecharModal();
}

// Alterar Data de Início
async function alterarDataInicio(novaDataInicio) {
    if (!novaDataInicio) {
        alert('A data de início é obrigatória.');
        return;
    }

    try {
        // Inclua o status atual no payload
        await axios.put(`/api/v1/eventos/${evento.value.id}/`, {
            data_inicio: novaDataInicio,
            status: evento.value.status, // Adiciona o status atual do evento
        });

        // Atualiza localmente o evento
        evento.value.data_inicio = novaDataInicio;
        alert('Data de início alterada com sucesso!');
    } catch (error) {
        console.error('Erro ao alterar data de início:', error);
        alert('Erro ao alterar a data de início. Tente novamente.');
    }
}


// Atualizar Status para Em Andamento
async function alterarParaEmAndamento() {
    try {
        // Enviar a atualização via API
        await axios.put(`/api/v1/eventos/${evento.value.id}/`, {
            status: 'em_atendimento',
        });

        // Atualizar o estado local do evento
        evento.value.status = 'em_atendimento';
        alert('O evento agora está em andamento.');
    } catch (error) {
        console.error('Erro ao atualizar o status do evento:', error);
        alert('Erro ao alterar o status do evento. Tente novamente.');
    }
}

// Finalizar Evento
async function finalizarEvento(statusAposEvento) {
    if (!statusAposEvento) {
        alert('O status após o evento é obrigatório.');
        return;
    }

    try {
        await axios.put(`/api/v1/eventos/${evento.value.id}/`, {
            status: 'finalizado',
            status_apos_evento: statusAposEvento,
        });
        evento.value.status = 'finalizado';
        alert('Evento finalizado com sucesso!');
    } catch (error) {
        console.error('Erro ao finalizar evento:', error);
    }
}


// Atualizar Data de Previsão
async function atualizarDataPrevisao(novaData) {
    try {
        // Verificar se o campo 'status' está disponível
        if (!evento.value.status) {
            alert('O status do evento é obrigatório.');
            return;
        }

        // Enviar a atualização com o status
        await axios.put(`/api/v1/eventos/${evento.value.id}/`, {
            data_final: novaData,
            status: evento.value.status, // Inclui o status atual do evento
        });

        // Atualizar o estado localmente
        evento.value.data_final = novaData;
        alert('Data de previsão atualizada com sucesso!');
    } catch (error) {
        console.error('Erro ao atualizar data de previsão:', error);
        alert('Erro ao atualizar a data de previsão. Verifique os dados enviados.');
    }
}

// Formatar Data
function formatarData(data) {
    if (!data) return "Sem previsão";

    // Interpretar a string de data corretamente como UTC
    const date = new Date(data);

    // Garantir que a data seja ajustada corretamente para o fuso horário local
    const adjustedDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    return adjustedDate.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
}

// Função para carregar o evento
async function carregarEvento(eventoId) {
    isLoading.value = true;
    error.value = null;

    try {
        const response = await axios.get(`/api/v1/eventos/${eventoId}/`);
        evento.value = response.data;

        // Carregar dados relacionados
        await Promise.all([carregarUnidades(), carregarEquipamentos(), carregarEntidades()]);

        // Mapear os IDs para nomes
        evento.value.unidade_nome = obterNomePorId(unidades.value, evento.value.unidade);
        evento.value.entidade_nome = obterNomePorId(entidades.value, evento.value.entidade);
        evento.value.equipamento_nome = obterNomePorId(equipamentos.value, evento.value.equipamento);

        // Carregar comentários
        await carregarComentarios(eventoId);
    } catch (err) {
        console.error('Erro ao carregar o evento:', err);
        error.value = 'Ocorreu um erro ao carregar os dados do evento.';
        router.push({ name: 'DashboardEquipamentos' }); // Redireciona em caso de erro
    } finally {
        isLoading.value = false;
    }
}



// Carregar evento ao montar o componente
onMounted(async () => {
    const eventoId = route.params.id;
    if (eventoId) {
        await carregarEvento(eventoId);
    } else {
        router.push({ name: 'DashboardEquipamentos' }); // Redireciona se o ID não estiver presente
    }
});

</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>